import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';

import './style.css';
import TOP from './views/top';
import Home from './views/home';
import NotFound from './views/not-found';
import ResetPassword from './views/ResetPassword';

const App = () => {
  return (
    <Router >
      <Routes>
        <Route path="/top" element={<TOP />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));