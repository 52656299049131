import './ResetPassword.css';
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPassword = () => {
    const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL_PROD
        : process.env.REACT_APP_API_BASE_URL_LOCAL;

    const loginApiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_LOGIN_API_BASE_URL_PROD
        : process.env.REACT_APP_LOGIN_API_BASE_URL_LOCAL;

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setMessage('パスワードが一致しません。');
            return;
        }

        const token = searchParams.get('token');
        try {
            console.log(token)
            const response = await axios.post(`${loginApiUrl}?action=reset-password`, { token, password });
            
            if (response.data.success) {
                setMessage('パスワードが正常にリセットされました。ログイン画面に移動します。');
                setTimeout(() => navigate('/'), 3000); // 3秒後にログイン画面にリダイレクト
            } else {
                console.log(response)
                setMessage('パスワードリセットに失敗しました。');
            }
        } catch (error) {
            setMessage('エラーが発生しました。もう一度お試しください。');
        }
    };

    return (
        <div className="reset-password-container">
            <h2 className="reset-password-title">パスワード再設定</h2>
            <form className="reset-password-form" onSubmit={handleSubmit}>
                <input 
                    className="reset-password-input"
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    placeholder="新しいパスワード" 
                    required 
                />
                <input 
                    className="reset-password-input"
                    type="password" 
                    value={confirmPassword} 
                    onChange={(e) => setConfirmPassword(e.target.value)} 
                    placeholder="パスワードを確認" 
                    required 
                />
                <button className="reset-password-button" type="submit">パスワードを再設定</button>
            </form>
            {message && <p className="reset-password-message">{message}</p>}
        </div>
    );
};

export default ResetPassword;