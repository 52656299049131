import './table4.css'
import React, { useEffect,useState, useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import Modal from './Modal';

import { format } from 'date-fns';
import axios from 'axios';

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
//import './TableComponent.css';

const initialData = [
  { date: '2024-01-01', column2: 'test1', column3: 'value3', column4: 'value4',column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  { date: '2024-02-01', column2: 'test2', column3: 'value3', column4: 'value4' ,column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  // 他のデータも追加
];


function formatDate(dateString) {
  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  //console.log(dateString)
  //console.log(dateString.latest_date)
  //console.log("出力0")

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function formatDate1(dateString) {
  /*
  console.log("出力1")
  console.log(dateString)
  console.log("出力1")*/
  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}年${month}月${day}日`;
}





function TableComponent4() {
  /*編集画面 */
  const [show, setShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const columns = React.useMemo(
    () => [
    { Header: 'No',
      accessor: 'ID',/*atupdated */
    },
    { Header: '作成日',
      accessor: 'CreatDay',/*atupdated */
     Cell: ({ value }) => {
        return formatDate(value);},/*format(new Date(value), 'yyyy-MM-dd');},*/
    },
    { Header: '会社名', accessor: 'Campnayname' ,
      Cell: ({ row }) => (
        <a href={row.original.URL} target="_blank" rel="noopener noreferrer">
          {row.original.Campnayname}
        </a>
      ),


    },
    { Header: '担当者', accessor: 'Person' },
    { Header: '連絡先', accessor: 'ContacNum' },
    /*{ Header: 'URL', accessor: 'URL' },*/

    { Header: 'メモ', accessor: 'Memo' },
    { Header: '詳細', accessor: 'C_button', 
      Cell: ({ row }) => (
        <button className ="bu_shousai" onClick={() => handleButtonClick(row)}>詳細</button>
      )
  
    },
  
  ],[]);

  const handleButtonClick = (row) => {
    
    setSelectedRow(row.original);
    

    setid(row.original.ID)
    setName(row.original.Campnayname)
    setNumber(row.original.ContacNum)
    setUrl(row.original.URL)
    setperson(row.original.Person)
    setmemo(row.original.Memo)


    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedRow(null);
  };

  const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL_PROD
        : process.env.REACT_APP_API_BASE_URL_LOCAL;


  /*表の更新 */
     /*企業登録*/
     const [id, setid] = useState('');
     const [name, setName] = useState('');
     const [number, setNumber] = useState('');
     const [url, setUrl] = useState('');
     const [Person, setperson] = useState('');
     const [memo, setmemo] = useState('');
   
     const [message, setMessage] = useState('');
   
     const handleSubmit = async (e) => {
       e.preventDefault();
   
       try {
        console.log(id)
         const response = await axios.post(`${apiUrl}?action=updateCampany`, { id,name,Person, url,number,memo });
         if (response.status === 200) {
           alert('更新が完了しました');
           setMessage('更新が完了しました');
           setName('');
           setNumber('');
           setUrl('');
         }
       } catch (error) {
         alert('データの登録に失敗しました');
         console.error('Error adding data:', error);
       }
     };

     
  /*表の表示 */
  const [data, setData] = useState(initialData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}?action=getCampanyList`);
        const resultData = response.data.map(row => ({
          ID: row.ID || '',
          CreatDay: row.CreatDay || '',
          Campnayname: row.Campnayname || '',
          Person: row.Person || '',
          ContacNum: row.ContacNum || '',
          URL: row.URL || '',
          Memo: row.Memo || '',
        }));
        setData(resultData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const [searchInput, setSearchInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const updateData = (rowIndex, columnId, value) => {
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  //console.log("updateData")
 
  const filteredData = useMemo(() => {
    return data.filter(row => {
      const date = new Date(row.datedorm);
      const isWithinDateRange =
        (!startDate || date >= startDate) && (!endDate || date <= endDate);
      const matchesSearchInput = Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchInput.toLowerCase())
      );
      return isWithinDateRange && matchesSearchInput;
    });
  }, [data, searchInput, startDate, endDate]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
      updateData,
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      
      <div className="controls">
        <div>フリーワード検索</div>
        <input
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search"
          className="controls_date"
        />
        <div>日付</div>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className="controls_date"
        />

        <div>～</div>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          className="controls_date"
        />
      </div>

      <div className="pagination">
        <button id = "pg_bu1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span id="pg_sp">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  backgroundColor: '#f2f2f2', // 背景色を設定
                  color: 'black', // 文字色を設定
                  padding: '10px', // パディングを設定
                  textAlign: 'left', // テキストの配置を設定
                }}
              >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>


      <Modal show={show} handleClose={handleClose}>
        {selectedRow && (
          <div id="C_shiire_toroku" className="top-container37">
          <div className="top-containerhattyukanri1">
            <div className="top-container38">
              <span className="top-text31">発注管理&gt;仕入れ先編集</span>
            </div>

            <form onSubmit={handleSubmit}>
            <div className="top-container39">
              <div className="top-container40">
                <div className="top-container41">
                  <span className="top-text32">業者名</span>
                  <input
                    type="text"
                    value = {name}
                    className="top-textinput16 input"
                    
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="top-container42">
                  <span className="top-text33">業者ID</span>
                  <input
                    type="text"
                    value = {id}
                    onChange={(e) => setid(e.target.value)}
                    readOnly
                    className="top-textinput17 input"
                  />
                </div>
              </div>
   
              <div className="top-container43">
                <div className="top-container44">
                  <span className="top-text34">担当者名</span>
                  <input
                    type="text"
                    value = {Person}
                    className="top-textinput18 input"
                    onChange={(e) => setperson(e.target.value)}
                  />
                </div>
                <div className="top-container45">
                  <span className="top-text35">連絡先</span>
                  <input
                    type="text"
                    value = {number}
                    className="top-textinput19 input"
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="top-container48">
                <div className="top-container49">
                  <span className="top-text37">URL</span>
                  <input
                    type="text"
                    value = {url}
                    className="top-textinput21 input"
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </div>
              </div>
              <div className="top-container50">
                <div className="top-container51">
                  <span className="top-text38">メモ</span>
                </div>
                <textarea

                  value = {memo}
                  className="top-textarea textarea"
                  onChange={(e) => setmemo(e.target.value)}

                ></textarea>
              </div>
              <div className="top-container52">
                <button  type="submit" className="top-button11 button">
                  保存する
                </button>
              </div>
            </div>
            </form>
          </div>
        </div>
        )}
      </Modal>

    </div>
  );
}

export default TableComponent4;


