import './table2.css'
import React, { useEffect,useState, useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { format } from 'date-fns';
import axios from 'axios';

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
//import './TableComponent.css';

const initialData = [
  { ID: '1', Name: '最終更新日',Date: '' },
  { ID: '2', Name: '2回前更新日',Date: ''},
  { ID: '3', Name: '3回前更新日',Date: ''},
  { ID: '4', Name: '4回前更新日',Date: ''},
  { ID: '5', Name: '5回前更新日',Date: ''},
];


function formatDate(dateString) {
  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

const columns = [
  { Header: 'No', accessor: 'ID' },
  { Header: '更新履歴', accessor: 'Name' },

  { Header: '更新日時',
    accessor: 'Date',
   Cell: ({ value }) => {
      return formatDate(value);},/*format(new Date(value), 'yyyy-MM-dd');},*/
  },
  
];



function TableComponent2() {
  const [data, setData] = useState(initialData);
    
  const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_BASE_URL_PROD
  : process.env.REACT_APP_API_BASE_URL_LOCAL;
    
    useEffect(() => {
      const fetchDates = async () => {
        try {
          const response = await axios.get(`${apiUrl}?action=getZaikoHistory`);
          console.log(response)
          console.log("=========")
          console.log(response.data)
          const dates = response.data.map(row => row.atupdated);
          console.log(dates)
          // 日付データをinitialDataに追加
          const updatedData = initialData.map((item, index) => ({
            ...item,
            Date: dates[index] || 'N/A' // 日付が存在しない場合は 'N/A'
          }));
  
          setData(updatedData);
        } catch (error) {
          console.error('Error fetching dates:', error);
        }
      };
  
      fetchDates();
    }, []);


  const [searchInput, setSearchInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const updateData = (rowIndex, columnId, value) => {
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  //console.log("updateData")
 
  const filteredData = useMemo(() => {
    return data.filter(row => {
      const date = new Date(row.datedorm);
      const isWithinDateRange =
        (!startDate || date >= startDate) && (!endDate || date <= endDate);
      const matchesSearchInput = Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchInput.toLowerCase())
      );
      return isWithinDateRange && matchesSearchInput;
    });
  }, [data, searchInput, startDate, endDate]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0 },
      updateData,
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  backgroundColor: '#f2f2f2', // 背景色を設定
                  color: 'black', // 文字色を設定
                  padding: '10px', // パディングを設定
                  textAlign: 'left', // テキストの配置を設定
                }}
              >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent2;


