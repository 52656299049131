import './table3.css'
import React, { useEffect,useState, useMemo } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { format } from 'date-fns';
import axios from 'axios';

import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
//import './TableComponent.css';

const customStyles_2 = {
  control: (provided, state) => ({
    ...provided,
    marginbottom: '10px',
    border: '1px solid #d3d3d3', /* 薄い灰色の枠線 */
    boxShadow: state.isFocused ? '0 0 0 1px black' : 'none', // フォーカス時のボーダー
    '&:hover': {
      border: '1px solid black' // ホバー時のボーダー
    }
  })
};

const initialData = [
  { date: '2024-01-01', column2: 'test1', column3: 'value3', column4: 'value4',column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  { date: '2024-02-01', column2: 'test2', column3: 'value3', column4: 'value4' ,column5: 'value5',column6: 'value6',column7: 'value7',column10: 'value8' },
  // 他のデータも追加
];

const StyledTable = styled.table`
  font-size: 12px; /* 希望のフォントサイズに変更 */
`;
function formatDate(dateString) {

  const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_BASE_URL_PROD
        : process.env.REACT_APP_API_BASE_URL_LOCAL;

  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  //console.log(dateString)
  //console.log(dateString.latest_date)
  //console.log("出力0")

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function formatDate1(dateString) {
  if (!dateString) return '';  // null または undefined の場合、空文字列を返す
  //console.log("出力1")
  //console.log(dateString)
  //console.log("出力1")

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月は0から始まるので+1する
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}年${month}月${day}日`;
}



export default  function  TableComponent3() {
  const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_BASE_URL_PROD
  : process.env.REACT_APP_API_BASE_URL_LOCAL;

  const [data, setData] = useState([]);


  useEffect(() => {
    fetchData_set();
  }, []);

  const sortedData = React.useMemo(() => {
    return data.sort((a, b) => a.order - b.order); // orderでソート
  }, [data]);

  const fetchData_set = async () => {
    

        try {
          const response = await axios.get(`${apiUrl}?action=getHatyuList`);
          console.log("発注リスト");
          console.log(response.data);
          //setData(response.data);
          updateDataWithKeikabi(response.data); // データが取得できたら計算

          console.log("発注リスト");
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      /*在庫リスト更新 */
      const updatehatyulistfromid = async (id) => {
        console.log(id);
        try {
          const response = await axios.post(`${apiUrl}?action=updateHatyuListFromId`, { id });

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };


      useEffect(() => {
        console.log("Updated data:");
        console.log(data);
      }, [data]); // `data` が変更されるたびにこの useEffect が実行される

    const handleStatusChange = async (id, columnId, value,nameID,keikabi) => {
      console.log(`Updated row ${id} column ${columnId} with value ${value} atkeikabi ${keikabi}`);

      try {
        console.log(id)
         const response = await axios.post(`${apiUrl}?action=updateData_hatyulist`, { id, columnId, value ,keikabi});
         
         console.log("平均値更新")
         console.log(response)
         if (response.status === 200) {
           fetchData_set();
           console.log(nameID)
           updatehatyulistfromid(nameID);
           
         }
       } catch (error) {
         alert('データの登録に失敗しました');
         console.error('Error adding data:', error);
       }
    };

   /* const [data, setData] = useState([]);*/
   const [latestDate, setLatestDate] = useState(null);

   useEffect(() => {
     const fetchLatestDate = async () => {
       try {
         const response = await axios.get(`${apiUrl}?action=getLatestHatyuUpdate`);
         console.log(response.data.latest_date)
         setLatestDate(response.data.latest_date);
       } catch (error) {
         console.error('Error fetching latest date:', error);
       }
     };
 
     fetchLatestDate();
   }, []);


   /*データベース削除 */
   const handleButtonClick = async (row) => {
    const idToDelete = row.original.Index; // row.Indexからrow.original.Indexに変更
    const nameID = row.original.ID; 
    console.log(row.original)
    try {
      // MySQL DBから該当する行を削除するリクエストを送信
      const response = await axios.post(`${apiUrl}?action=deleteHatyu`, { id: idToDelete });
      if (response.status === 200) {
        // 成功した場合、テーブルを更新
        fetchData_set(); // 最新のデータを再取得して表示
        console.log(nameID)
        updatehatyulistfromid(nameID);
        alert('行が削除されました');
      } else {
        alert('削除に失敗しました');
      }
    } catch (error) {
      console.error('エラーが発生しました', error);
      alert('エラーが発生しました');
    }
  };

  const handleCopy = async (row) => {
    try {
      // コピーしたデータをバックエンドに送信
      const idcopy = row.original.Index; // row.Indexからrow.original.Indexに変更
      const ordercopy = row.original.order; 
      const response = await axios.post(`${apiUrl}?action=copyhatyulist`, { id: idcopy , order: ordercopy });
      console.log("コピーデータ")
      console.log(response)
      if (response.status === 200) {
        // 挿入後にデータを再読み込み
        fetchData_set(); // 最新のデータを再取得して表示
      } else {
        console.error('Failed to insert row');
      }
    } catch (error) {
      console.error('Error copying row:', error);
    }
  };

   const columns = React.useMemo(
    () => [

      //{ Header: 'No', accessor: 'Index' },
      {
        
        Header: '発注日',
        accessor: 'datedorm',
        Cell: ({ value }) => formatDate(value),
      },
      { Header: '品番', accessor: 'ID' },
      { Header: '商品名', accessor: 'Name' },
      
      //{ Header: '仕入先', accessor: 'Hiiresaki' },

      { Header: '入荷数', 
        accessor: 'hatyusu' ,
        Cell: ({ value, row }) => (
          <input
            type="text"
            value={value ?? ''}
            onChange={(e) => handleStatusChange(row.original.Index,'hatyusu', e.target.value,row.original.ID, e.target.value, row.original.keikabi)}
            style={{ width: '50px' }} // inputタグにwidthを追加
          />
        ),
      },

      {
        
        Header: '到着日',
        accessor: 'Totyakubi',

        Cell: ({ value, row,}) => (
          <input
            type="date"
            value={formatDate(value)}
            onChange={(e) =>  handleStatusChange(row.original.Index,'Totyakubi', e.target.value,row.original.ID,row.original.keikabi)}
          />
        ),


      },

      {
        Header: '発注',
        accessor: 'Hayuust',
        Cell: ({ row, value }) => (
          <select
            value={value || ''}
            onChange={(e) => {
              const newValue = e.target.value;

              // 発注状況が「到着済み」に変更された場合、かつTotyakubiが空の場合のみ更新
              if (newValue === '到着済み' && !row.original.Totyakubi) {
                const today = new Date().toISOString().split('T')[0]; // 本日の日付 (YYYY-MM-DD形式)
                
                // 到着日が未設定の場合のみ、到着日を本日の日付に自動更新
                handleStatusChange(row.original.Index, 'Totyakubi', today, row.original.ID, row.original.keikabi);
              }

              // 発注ステータスの変更
              handleStatusChange(row.original.Index, 'Hayuust', newValue, row.original.ID, row.original.keikabi);
            }}
          >
            <option value="発注済み">発注済み</option>
            <option value="到着済み">到着済み</option>
          </select>
        ),
      },
      /*
      { Header: 'メモ', 
        accessor: 'Memo	',
        Cell: ({ value, row }) => (
          <input
            type="text"
            value={value || ''}
            onChange={(e) => handleStatusChange(row.original.Index,'Memo',e.target.value,)}
            style={{ width: '150px' }} // inputタグにwidthを追加
          />
        ),


      },*/
      {
        Header: 'URL',
        accessor: 'Shiiresaki_URL',
        getHeaderProps: () => ({
          className: 'custom-header',
        }),
        Cell: ({ value }) => (
          <a href={value} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ),
      },
      {
        Header: '経過日数',
        accessor: 'keikabi',
      },


      /*{
        Header: '経過日数',
        accessor: 'keikabi',
        Cell: ({ row }) => {
          const hayuust = row.original.Hayuust;
          const orderDate = row.original.datedorm;
          
          // 経過日数を計算
          const calculateDaysElapsed = (orderDate) => {
            const today = new Date();
            const orderDateObj = new Date(orderDate);
            const timeDifference = today - orderDateObj;
            const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) +1;
            return daysElapsed;
          };
      
          // Hayuustの状態によって表示を切り替え
          if (hayuust === '到着済み') {
            return 0;
          } else if (hayuust === '発注済み' && orderDate) {
            return calculateDaysElapsed(orderDate);
          } else {
            return ''; // 発注日がない場合など
          }
        },
      },*/

       


      { Header: '削除', accessor: 'C_button1', 
        Cell: ({ row }) => (
          <button className ="bu_shousai1-1" onClick={() => handleButtonClick(row)}>削除</button>
        )
      },
      {
        Header: '複製',
        accessor: 'Copy',
        Cell: ({ row }) => (
          <button className ="bu_shousai1-2" onClick={() => handleCopy(row)}>Copy</button>
        )
      }


    ],
    []
  );
  
  // 経過日数を計算し、データに追加する関数
const updateDataWithKeikabi = (data_) => {
  const updatedData = data_.map(row => {
    const hayuust = row.Hayuust;
    const orderDate = row.datedorm;
    const Totyakubi = row.Totyakubi;

    // 経過日数を計算
    const calculateDaysElapsed = (startDate, endDate) => {
      const startDateObj = new Date(startDate);
      const endDateObj = endDate ? new Date(endDate) : new Date();
      const timeDifference = endDateObj - startDateObj;
      const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
      return daysElapsed;
    };

    let keikabiValue = '';

    // Totyakubi（到着日）が存在すれば、それを基準に経過日数を計算
    if (hayuust === '発注済み' && orderDate) {
      // Totyakubiがない場合は、発注日から現在までの経過日数を計算
      keikabiValue = calculateDaysElapsed(orderDate);
    }
    else if (Totyakubi && orderDate) {
      keikabiValue = calculateDaysElapsed(orderDate, Totyakubi);
    } else if (hayuust === '到着済み') {
      keikabiValue = 0;
    }

    return { ...row, keikabi: keikabiValue };
  });

  setData(updatedData);
};

 

  const [searchInput, setSearchInput] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const updateData = (rowIndex, columnId, value) => {
    setData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
 
 //console.log("updateData")
 const [filterType, setFilterType] = useState('発注済み'); // フィルタの初期値を '不足' に設定

 const filterOptions = [
   { value: '全体', label: '全体' },
   { value: '発注済み', label: '発注済み' },
 ];
 // フィルタドロップダウンの選択ハンドラ
   const handleFilterChange = selectedOption => {
     setFilterType(selectedOption.value);
   };

  const filteredData = useMemo(() => {
    return sortedData.filter(row => {
      const date = new Date(row.datedorm);
      const isWithinDateRange =
        (!startDate || date >= startDate) && (!endDate || date <= endDate);
      const matchesSearchInput = Object.values(row).some(value =>
        String(value).toLowerCase().includes(searchInput.toLowerCase())
      );
      
        // "不足" フィルタ条件
      const matchesFilterType = filterType === '発注済み' ? row.Hayuust == "発注済み" : true;

      return isWithinDateRange && matchesSearchInput && matchesFilterType;
    });
  }, [sortedData, searchInput, startDate, endDate,filterType]);


  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,

      data : filteredData,
      initialState: { pageIndex: 0 },
      //updateData,
    },
    useSortBy,
    usePagination
  );

  return (
    <div>
      <span>最新更新日:　{formatDate1(latestDate)}</span>
      <div className="controls">
        <div>フリーワード検索</div>
        <input
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          placeholder="Search"
          className="controls_date"
        />
        <div>日付</div>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className="controls_date"
        />

        <div>～</div>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          className="controls_date"
        />
      </div>
      {/* フィルタドロップダウン */}
      <div className = "c-button-fusoku">
        <div className = "button-fusoku">
          <Select
            value={filterOptions.find(option => option.value === filterType)}
            onChange={handleFilterChange}
            options={filterOptions}
            styles={customStyles_2}
            placeholder="未到着フィルタ"
          />
        </div>
      </div>
      <div className="pagination">
        <button id = "pg_bu1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span id="pg_sp">
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  backgroundColor: '#f2f2f2', // 背景色を設定
                  color: 'black', // 文字色を設定
                  padding: '10px', // パディングを設定
                  textAlign: 'left', // テキストの配置を設定
                }}
              >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </div>
  );
};

/*
export default TableComponent3;*/



