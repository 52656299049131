import React , { useState } from 'react'
import { Link  , useNavigate } from 'react-router-dom'
import Modal from 'react-modal';
import axios from 'axios';

import PropTypes from 'prop-types'
import './sign-in1.css'

const SignIn1 = (props) => {

  const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_BASE_URL_PROD
  : process.env.REACT_APP_API_BASE_URL_LOCAL;

const loginApiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_LOGIN_API_BASE_URL_PROD
  : process.env.REACT_APP_LOGIN_API_BASE_URL_LOCAL;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [Email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const handleLogin = async (e) => {
      e.preventDefault();
      console.log(Email)
      console.log(password)
      console.log(loginApiUrl)
      try {
          const response = await axios.post(`${loginApiUrl}?action=login`, { Email, password });
          if (response.data.success) {
              navigate('/top');  // 認証成功時にTOPページへ遷移
          } else {
              alert('ログイン失敗');
          }
      } catch (error) {
          console.error('Login error:', error);
          alert('ログインエラー');
      }
  };


  /*パスワード再設定 */
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setMessage('');
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Email) {
      setMessage('メールアドレスを入力してください。');
      return;
    }
    console.log(loginApiUrl)
    console.log(apiUrl)
    console.log(Email)
    try {
      const response = await axios.post(`${loginApiUrl}?action=forgot-password`, { Email },{
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      console.log(response)
      if (response.data.success) {
        setMessage('パスワード再設定のリンクをメールで送信しました。');
      } else {
        
        setMessage('該当するメールアドレスが見つかりません。');
      }
    } catch (error) {
      
      console.error('Error occurred:', error);
      setMessage('エラーが発生しました。もう一度お試しください。');
    }
  };
  const [showPassword, setShowPassword] = useState(false);


  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="sign-in1-container thq-section-padding">
      <div className="sign-in1-max-width thq-section-max-width">
        <div className="sign-in1-form-root">
          <div className="sign-in1-form">
            <div className="sign-in1-title-root">
              <h2 className="thq-heading-2">{props.heading1}</h2>
              <div className="sign-in1-have-an-account-login"></div>
            </div>
            <form className="sign-in1-form1"  onSubmit={handleLogin}>
              <div className="sign-in1-email">
                <label htmlFor="thq-sign-in-1-email" className="thq-body-large">
                  Email
                </label>
                <input
                  type="email"
                  id="thq-sign-in-1-email"
                  required="true"
                  placeholder="Email address"
                  className="sign-in1-textinput thq-body-large thq-input"
                  onChange={(e) => setEmail(e.target.value)}

                />
              </div>
              <div className="sign-in1-password">
                <div className="sign-in1-container1">
                  <label
                    htmlFor="thq-sign-in-1-password"
                    className="thq-body-large"
                  >
                    Password
                  </label>

                  <div className="sign-in1-hide-password" onClick={handleTogglePassword}>
                    <svg viewBox="0 0 1024 1024" className="sign-in1-icon">
                      <path d="M317.143 762.857l44.571-80.571c-66.286-48-105.714-125.143-105.714-206.857 0-45.143 12-89.714 34.857-128.571-89.143 45.714-163.429 117.714-217.714 201.714 59.429 92 143.429 169.143 244 214.286zM539.429 329.143c0-14.857-12.571-27.429-27.429-27.429-95.429 0-173.714 78.286-173.714 173.714 0 14.857 12.571 27.429 27.429 27.429s27.429-12.571 27.429-27.429c0-65.714 53.714-118.857 118.857-118.857 14.857 0 27.429-12.571 27.429-27.429zM746.857 220c0 1.143 0 4-0.571 5.143-120.571 215.429-240 432-360.571 647.429l-28 50.857c-3.429 5.714-9.714 9.143-16 9.143-10.286 0-64.571-33.143-76.571-40-5.714-3.429-9.143-9.143-9.143-16 0-9.143 19.429-40 25.143-49.714-110.857-50.286-204-136-269.714-238.857-7.429-11.429-11.429-25.143-11.429-39.429 0-13.714 4-28 11.429-39.429 113.143-173.714 289.714-289.714 500.571-289.714 34.286 0 69.143 3.429 102.857 9.714l30.857-55.429c3.429-5.714 9.143-9.143 16-9.143 10.286 0 64 33.143 76 40 5.714 3.429 9.143 9.143 9.143 15.429zM768 475.429c0 106.286-65.714 201.143-164.571 238.857l160-286.857c2.857 16 4.571 32 4.571 48zM1024 548.571c0 14.857-4 26.857-11.429 39.429-17.714 29.143-40 57.143-62.286 82.857-112 128.571-266.286 206.857-438.286 206.857l42.286-75.429c166.286-14.286 307.429-115.429 396.571-253.714-42.286-65.714-96.571-123.429-161.143-168l36-64c70.857 47.429 142.286 118.857 186.857 192.571 7.429 12.571 11.429 24.571 11.429 39.429z"></path>
                    </svg>
                    <span className="thq-body-small">{showPassword ? 'Hide' : 'Show'}</span>
                  </div>

                </div>

                <input
                  type={showPassword ? 'text' : 'password'}
                  id="thq-sign-in-1-password"
                  required
                  placeholder="Password"
                  className="sign-in1-textinput1 thq-body-large thq-input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <button type="submit" className="sign-in1-button thq-button-filled">ログイン</button>
            </form>
            <div className="sign-in1-container2">
              <div className="sign-in1-container3">
                <div className="sign-in1-terms-agree">
                  <p className="thq-body-large">
                    <span>
                      By continuing, you agree to the Terms of use and Privacy
                      Policy.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </p>
                </div>
              </div>
              <div className="sign-in1-container4">

              <button　className="sign-in1-text8 thq-body-small" onClick={openModal}>パスワードを忘れた場合</button>
              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="パスワード再設定"
              >
                <h2>パスワードを忘れた場合</h2>
                <form onSubmit={handleSubmit}>
                  <label>
                    登録メールアドレス:
                    <input type="email" value={Email} onChange={handleEmailChange} required />
                  </label>
                  <button type="submit">リンクを送信</button>
                </form>
                {message && <p>{message}</p>}
                <button onClick={closeModal}>閉じる</button>
              </Modal>

              </div>
            </div>
          </div>
        </div>
        <div className="sign-in1-container5">
          <div className="sign-in1-divider"></div>
        </div>
      </div>
    </div>
  )
}

SignIn1.defaultProps = {
  action1: 'Sign in',
  heading1: '在庫管理システム',
  content1: 'New to our community',
  action2: 'Create an account',
}

SignIn1.propTypes = {
  action1: PropTypes.string,
  heading1: PropTypes.string,
  content1: PropTypes.string,
  action2: PropTypes.string,
}

export default SignIn1
